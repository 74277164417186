/* eslint-disable vue/multi-word-component-names */
<template>
  <div class="container" id="loading">
    <div class="loading" v-for="i in 16" :key="i"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
}
</script>