<template>
  <div>
    <MobileMessageComponent v-if="!isMobile" />
    <LoadingComponent v-else />
    <NotificationComponent ref="notification" />
  </div>
</template>

<script>
import MobileMessageComponent from '../components/MobileMessageComponent.vue'
import LoadingComponent from '../components/LoadingComponent.vue'
import NotificationComponent from '../components/NotificationComponent.vue'

export default {
  name: 'IndexView',
  components: { MobileMessageComponent, LoadingComponent, NotificationComponent },
  data() {
    return {
      isMobile: true,
      userId: null
    }
  },
  async created() {
    this.isMobile = /android|bb\d+|meego|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iphone|ipod|ipad|kindle|midp|mmp|mobile|firefox|netfront|opera m(ob|in)i|palm|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(navigator.userAgent)
    if (this.isMobile) {
      await this.initUser()
    }
  },
  methods: {
    async initUser() {
      const tgUser = window.Telegram.WebApp.initDataUnsafe?.user
      if (!tgUser) {
        this.showNotification('Unable to get user data')
        return
      }

      this.userId = tgUser.id
      const userName = tgUser.username || tgUser.first_name
      const urlParams = new URLSearchParams(window.location.search)
      const refUserId = urlParams.get('ref')

      if (!this.userId || !userName) {
        this.showNotification('Unable to retrieve user ID')
        return
      }

      try {
        const response = await fetch('init_user.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: this.userId, userName, refUserId })
        })

        const data = await response.json()

        if (data.success && window.location.hash && window.location.hash.indexOf('tgWebAppData') !== -1) {
          this.$router.push('/home')
        } else {
          console.error('User Acquisition Error')
          this.showNotification('User Acquisition Error')
        }
      } catch (error) {
        console.error('User Acquisition Error:', error)
        this.showNotification('User Acquisition Error')
      }
    },
    showNotification(message) {
      if (this.$refs.notification) {
        this.$refs.notification.show(message)
      } else {
        // Delay to ensure the component is mounted
        setTimeout(() => {
          if (this.$refs.notification) {
            this.$refs.notification.show(message)
          } else {
            console.error('Notification component still not found after delay')
          }
        }, 100)
      }
    }
  }
}
</script>