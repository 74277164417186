/* eslint-disable vue/multi-word-component-names */
<template>
  <div id="notification-container" class="notification-container"></div>
</template>

<script>
export default {
  name: 'NotificationComponent',
  methods: {
    show(message) {
      const notificationContainer = document.getElementById('notification-container')
      const notification = document.createElement('div')
      notification.className = 'notification'
      
      const image = document.createElement('img')
      image.src = require('@/assets/img/star.png')
      image.className = 'notification-image'

      const text = document.createElement('span')
      text.textContent = message

      notification.appendChild(image)
      notification.appendChild(text)
      notificationContainer.appendChild(notification)

      setTimeout(() => {
        notification.classList.add('fade')
      }, 3000)

      setTimeout(() => {
        notification.remove()
      }, 4000)
    }
  }
}
</script>