<template>
    <div class="coins">
      <a>{{ formattedCoins }}</a>
      <p>Amount of your Rikcoins</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BalanceComponent',
    props: {
      coins: {
        type: Number,
        required: true
      }
    },
    computed: {
      formattedCoins() {
        return this.coins.toFixed(5);
      }
    }
  }
  </script>
  