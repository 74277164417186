<template>
  <div class="energy-progress">
    <a><img src="@/assets/img/energy.svg"> <span id="progress-count">{{ currentEnergy }}</span>/<span id="progress-total">{{ totalEnergy }}</span></a>
    <div class="progress">
      <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnergyProgress',
  props: ['currentEnergy', 'totalEnergy'],
  computed: {
    progressPercentage() {
      return (this.currentEnergy / this.totalEnergy) * 100
    }
  }
}
</script>