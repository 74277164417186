<template>
  <div class="clicker">
    <img id="clicker-img" ref="clickerImg" @touchstart="handleTouchStart" :src="clickerImagePath">
  </div>
</template>

<script>
export default {
  name: 'ClickerComponent',
  props: {
    coins: {
      type: Number,
      required: true
    },
    energy: {
      type: Number,
      required: true
    },
    energyLimit: {
      type: Number,
      required: true
    },
    multitapCoin: {
      type: Number,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    rankName: {
      type: String,
      required: true
    },
    rSpeed: { // Используем rSpeed вместо racharging
      type: Number,
      required: true
    }
  },
  data() {
    return {
      localCoins: Number(this.coins),
      progress: Number(this.energy),
      coinUpdates: [],
      energyUpdates: [],
      recoverySpeed: Number(this.rSpeed) * 1000, // Убедитесь, что используется правильное значение
      saveInterval: 700,
      clickerImages: {
        'Rookie': require('@/assets/img/ranks/rookie.png'),
        'Silver': require('@/assets/img/ranks/silver.png'),
        'Skilled': require('@/assets/img/ranks/skilled.png'),
        'Advanced': require('@/assets/img/ranks/advanced.png'),
        'Expert': require('@/assets/img/ranks/expert.png')
      }
    }
  },
  computed: {
    clickerImagePath() {
      return this.clickerImages[this.rankName];
    }
  },
  watch: {
    coins(newCoins) {
      this.localCoins = newCoins;
    },
    energy(newEnergy) {
      this.progress = newEnergy;
    },
    localCoins(newCoins) {
      this.$emit('update:coins', newCoins);
    },
    progress(newEnergy) {
      this.$emit('update:energy', newEnergy);
    },
    rSpeed(newRSpeed) {
    console.log('rSpeed изменился на:', newRSpeed);
    this.recoverySpeed = newRSpeed * 1000;
    clearInterval(this.recoveryInterval);
    this.recoveryInterval = setInterval(this.recoverEnergy, this.recoverySpeed);
  }
  },
  mounted() {
    setInterval(this.recoverEnergy, this.recoverySpeed);
    setInterval(this.sendData, this.saveInterval);
  },
  methods: {
    handleTouchStart(event) {
      if (this.progress <= 0) return;
      for (let touch of event.touches) {
        this.handleTouch(touch);
      }
    },
    handleTouch(touch) {
      const img = this.$refs['clickerImg'];
      const rect = img.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const offsetX = (centerX - x) / 10;
      const offsetY = (centerY - y) / 10;

      img.style.transform = `rotateX(${offsetY}deg) rotateY(${offsetX}deg) scale(0.95)`;

      setTimeout(() => {
        img.style.transform = 'rotateX(0deg) rotateY(0deg) scale(1)';
      }, 200);

      this.localCoins += this.multitapCoin;
      this.coinUpdates.push(this.localCoins);

      this.progress -= 1;
      this.energyUpdates.push(this.progress);

      this.showFloatingText(touch);

      if (this.progress <= 0) {
        img.style.filter = 'grayscale(1)';
      }
    },
    showFloatingText(touch) {
      const floatingText = document.createElement('div');
      floatingText.classList.add('floating-text');
      floatingText.textContent = `+${this.multitapCoin.toFixed(5)}`;

      const floatingTextWidth = 90;
      const windowWidth = window.innerWidth;
      let left = touch.clientX;
      if (left + floatingTextWidth > windowWidth) {
        left = touch.clientX - floatingTextWidth;
      }
      floatingText.style.left = `${left}px`;
      floatingText.style.top = `${touch.clientY}px`;
      document.body.appendChild(floatingText);

      setTimeout(() => {
        floatingText.remove();
      }, 1000);
    },
    sendData() {
      if (this.coinUpdates.length > 0) {
        fetch('save.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `coins=${this.coinUpdates.join(',')}&energy=${this.energyUpdates.join(',')}&user_id=${this.userId}`
        }).then(() => {
          this.coinUpdates = [];
          this.energyUpdates = [];
          console.log('Okey: ', this.recoverySpeed, '+', this.rSpeed);
        }).catch(error => {
          console.error('Error saving data to database:', error);
        });
      }
    },
    recoverEnergy() {
      if (this.progress < this.energyLimit) {
        this.progress += 1;
        if (this.progress > 0) {
          this.$refs['clickerImg'].style.filter = 'none';
        }
      }
    }
  }
}
</script>
